function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export default function booking_recalculate_parameters(bookingForm, hotelData, datePicker) {
  const hotelCode = parseInt(document.getElementById('hotel_code').value);
  const id_stile = document.getElementById('id_stile');
  const id_albergo = document.getElementById('id_albergo');
  const dc = document.getElementById('dc');
  const notti_1 = document.getElementById('notti_1');
  const gg = document.getElementById('gg');
  const mm = document.getElementById('mm');
  const aa = document.getElementById('aa');

  if (hotelCode > 0) {
    id_albergo.value = hotelCode;
    id_stile.value = hotelData[hotelCode].style;
    dc.value = hotelData[hotelCode].dc;
    if (hotelCode === 28297) {
      bookingForm.setAttribute('action', 'https://www.blastnessbooking.com/premium/group_index2.html');
      id_albergo.setAttribute('name', 'id_gruppo');
      dc.setAttribute('name', 'dc_gruppo');
    } else {
      // we need to re-set attributes because the user could make two
      // subsequent requests
      bookingForm.setAttribute('action', 'https://www.blastnessbooking.com/premium/index2.html');
      id_albergo.setAttribute('name', 'id_albergo');
      dc.setAttribute('name', 'dc');
    }
  }

  const checkin = datePicker.selectedDates[0];
  const checkout = datePicker.selectedDates[1];
  const checkinMonth = checkin.getMonth() + 1;
  gg.value = checkin.getDate().toString().padStart(2, '0');
  mm.value = checkinMonth.toString().padStart(2, '0');
  aa.value = checkin.getFullYear();
  notti_1.value = dateDiffInDays(checkin, checkout);

  const childrenage = document.getElementsByName('childrenage[]');
  if (childrenage.length > 0) {
    for (let i = 0; i < childrenage.length; i++) {
      bookingForm.insertAdjacentHTML('beforeend',
        '<input type="hidden" name="st1bamb' + (i + 1) + '" value="' + childrenage[i].value + '" />');
    }
  }
}
