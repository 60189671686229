// core version + navigation, pagination modules:
// import Swiper, {A11y, Autoplay, EffectFade, Lazy, Navigation, Pagination} from 'swiper';
// import flatpickr from 'flatpickr';
// import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import booking_recalculate_parameters from '../util/bookingRecalculateParameters';

export default {
  finalize() {
    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia('(min-width:1024px)');

    /* global Swiper */
    // keep track of swiper instances to destroy later
    const enableSwiper = function () {
      if (window.gallery !== undefined) {
        window.gallery.destroy(true, true);
      }
      if (breakpoint.matches) {
        // init slideshows
        console.log('Enabling Swiper');
        // Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay, A11y]);
        window.gallery = new Swiper('.swiper-accommodation-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
          },
          autoplay: {
            delay: 5000,
          },
          lazyLoading: true,
          lazy: {
            loadPrevNext: true,
          },
          resizeObserver: true,
        });
      }
    };

    const breakpointChecker = function () {
      enableSwiper();
      if (breakpoint.matches) {
        initPicker();
      } else {
        initPickerModal();
      }
    }

    breakpoint.addEventListener('change', breakpointChecker);
    breakpointChecker(breakpoint);

    window.addEventListener('resize', breakpointChecker);

    /* global flatpickr, rangePlugin, locale */
    let datePicker;

    function initPicker() {
      datePicker = flatpickr('#checkin', {
        mode: 'range',
        minDate: 'today',
        locale: locale.current,
        formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
        'plugins': [new rangePlugin({input: '#checkout'})],
      });
    }

    function initPickerModal() {
      datePicker = flatpickr('#checkin-mobile', {
        mode: 'range',
        minDate: 'today',
        locale: locale.current,
        formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
        'plugins': [new rangePlugin({input: '#checkout-mobile'})],
      });
    }

    window.booking = window.bookingWidget || {};
    let bf_single = document.querySelector('#booking-widget #form_qr');
    bf_single.addEventListener('submit', function (e) {
      e.preventDefault();
      booking_recalculate_parameters(bf_single, locale.hotel_data, datePicker);
      bf_single.submit();
    });

    let bf_single_mobile = document.querySelector('#booking-widget-mobile #form_qr');
    bf_single_mobile.addEventListener('submit', function (e) {
      e.preventDefault();
      booking_recalculate_parameters(bf_single, locale.hotel_data, datePicker);
      bf_single.submit();
    });
  },
};
