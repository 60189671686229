// core version + navigation, pagination modules:
// import Swiper, {Pagination, EffectFade, Autoplay, A11y} from 'swiper';
import booking_recalculate_parameters from '../util/bookingRecalculateParameters';

export default {
  finalize() {
    // configure Swiper to use modules
    /* global Swiper */
    new Swiper('.swiper-home-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      autoplay: {
        delay: 5000,
      },
    });

    /* global flatpickr, rangePlugin, locale */
    const datePicker = flatpickr('#checkin', {
      mode: 'range',
      minDate: 'today',
      locale: locale.current,
      formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
      'plugins': [new rangePlugin({input: '#checkout'})],
    });

    window.booking = window.bookingWidget || {};
    let bookingForm = document.getElementById('form_qr');
    bookingForm.addEventListener('submit', function (e) {
      e.preventDefault();
      booking_recalculate_parameters(bookingForm, locale.hotel_data, datePicker);
      bookingForm.submit();
    });


  },
};

/*
https://www.blastnessbooking.com/premium/group_index2.html?lingua_int=itaid_gruppo=28297&dc_gruppo=8706&gg=21&mm=06&aa=2024&notti_1=7&tot_camere=1&tot_adulti=2&tot_bambini=0
https://www.blastnessbooking.com/premium/group_index2.html?lingua_int=ita&id_stile=20598&id_gruppo=28297&dc_gruppo=8706&notti_1=1&gg=22&mm=01&aa=2024&tot_camere=1&tot_adulti=2&tot_bambini=0
  https://www.blastnessbooking.com/premium/index2.html?lingua_int=ita&id_stile=20505&id_albergo=27254&dc=4199&notti_1=1&gg=22&mm=01&aa=2024&tot_camere=1&tot_adulti=2&tot_bambini=0
*/
