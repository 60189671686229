// import external dependencies
//import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import singleAccommodation from './routes/single-accommodation';
import singlePost from './routes/single-post';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  home,
  singleAccommodation,
  singlePost,
});

Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1; // getMonth() is zero-based
  const dd = this.getDate();

  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('');
}

// Load Events
if (document.readyState !== 'loading') {
  routes.loadEvents();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    routes.loadEvents();
  }, false);
}

// jQuery(document).ready(() => routes.loadEvents());
