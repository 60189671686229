//import flatpickr from 'flatpickr';
//import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';

import booking_recalculate_parameters from '../util/bookingRecalculateParameters';

export default {
  finalize() {
    /* global flatpickr, rangePlugin, locale */
    const datePicker = flatpickr('#checkin', {
      mode: 'range',
      minDate: 'today',
      locale: locale.current,
      formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
      'plugins': [new rangePlugin({input: '#checkout'})],
    });

    window.booking = window.bookingWidget || {};
    let bf_sp = document.getElementById('form_qr');
    bf_sp.addEventListener('submit', function (e) {
      e.preventDefault();
      booking_recalculate_parameters(bf_sp, locale.hotel_data, datePicker);
      bf_sp.submit();
    });
  },
};
